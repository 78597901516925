import { VNode, CreateElement } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextToUrl extends Vue {
  @Prop()
  private text?: string

  regex = /https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+/

  public render(h: CreateElement): VNode {
    let processTextArray = this.parseText();
    return <div>
      { processTextArray.map((text) => {
        if(text.match(this.regex)) {
          return <a href={text} target="_blank">{text}</a>
        } else {
          return text
        }
      })}
    </div>
  }

  parseText() {
    let processText = this.text || ''

    let match
    let matchObj: any = {}
    let count = 0

    while ((match = this.regex.exec(processText)) != null) {
      count += 1
      matchObj[`[${count}]`] = match[0]
      processText = processText.replace(match[0], `[${count}]`)
    }

    const keys = Object.keys(matchObj)

    keys.forEach((key) => {
      processText = processText.replace(key, `,${matchObj[key]},`)
    })
    return processText.split(',')
  }
}