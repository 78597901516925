export default class WebRTC {
  static API_KEY = process.env.VUE_APP_SKYWAY_API_KEY;
  static peer: any;
  static mediaconnection: any;
  static screenShare: any;
  static localStream: any;
  static videoIds: any = [];
  static useVideoId: number = 0;
  static disableTimer: Function;

  static init(
    finishFunction: Function,
    errorFunction: Function,
    callFunction?: Function
  ) {
    return new Promise(resolve => {
      this.getVideos().then(() => {
        this.createLocalStream(finishFunction, errorFunction).then(() => {
          this.peer.on("call", (call: any) => {
            (<any>document.getElementById("callSound")!).pause();
            this.getVideos().then(() => {
              if (!this.mediaconnection) {
                callFunction ? callFunction() : null;
              }
              this.mediaconnection = call;
              this.mediaconnection.answer(this.localStream);
              this.startStream();
            });
          });
          this.peer.on("error", (err: any) => {
            finishFunction();
            errorFunction();
            alert(err.message);
          });
          resolve();
        });
      });
    });
  }

  static getVideos() {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          this.videoIds = [];
          devices.forEach((device: any) => {
            if (device.kind == "videoinput") {
              this.videoIds.push(device.deviceId);
            }
          });
          if (
            this.videoIds.length > 1 &&
            !this.videoIds[0] &&
            !this.videoIds[1]
          ) {
            this.videoIds = ["user", "environment"];
          }
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  static createInstance() {
    // this.peer = new Peer({key: this.API_KEY, debug: 3})
    // this.screenShare = ScreenShare.create({debug: true})
    this.peer = new Peer({ key: this.API_KEY });
    this.screenShare = ScreenShare.create();
  }

  static call(
    finishFunction: Function,
    callFunction: Function,
    callErrorFunction: Function
  ) {
    this.createInstance();
    return new Promise((resolve, reject) => {
      this.peer.on("open", () => {
        this.init(finishFunction, callErrorFunction, callFunction)
          .then(() => {
            resolve(this.peer.id);
          })
          .catch(() => {
            reject();
          });
      });
    });
  }

  static take(
    webRtcId: string,
    finishFunction: Function,
    errorFunction: Function
  ) {
    this.createInstance();
    this.peer.on("open", () => {
      this.init(finishFunction, errorFunction).then(() => {
        this.mediaconnection = this.peer.call(webRtcId, this.localStream, {
          videoReceiveEnabled: true
        });
        this.startStream();
      });
    });
  }

  static switchCamera(useCamera: boolean, finishFunction: Function) {
    let videoConf;
    if (this.videoIds[0] == "user") {
      videoConf = { facingMode: this.videoIds[this.useVideoId] };
    } else {
      videoConf = { deviceId: this.videoIds[this.useVideoId] };
    }

    if (this.mediaconnection) {
      var peerid = this.mediaconnection.peer;
      this.mediaconnection.close();
    }
    this.closeLocalStream();
    this.disableTimer();
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: useCamera ? videoConf : false })
      .then(stream => {
        (<any>document.getElementById("localStream")).srcObject = stream;
        (<any>document.getElementById("localStream"))
          .play()
          .catch(console.error);
        this.localStream = stream;
        setTimeout(() => {
          this.mediaconnection = this.peer.call(peerid, stream, {
            videoReceiveEnabled: true
          });
          this.startStream();
        }, 500);
      })
      .catch(function(e) {
        finishFunction();
        alert("カメラの起動に失敗しました");
      });
  }

  static changeCamera(finishFunction: Function) {
    if (this.useVideoId == this.videoIds.length - 1) {
      this.useVideoId = 0;
    } else {
      this.useVideoId++;
    }
    this.switchCamera(true, finishFunction);
  }

  static canScreenShare() {
    return this.screenShare && this.screenShare.isScreenShareAvailable();
  }

  static callScreenShare(finishFunction: Function) {
    this.screenShare
      .start({
        frameRate: 1
      })
      .then((vstream: any) => {
        this.disableTimer();
        let peerid = this.mediaconnection.peer;
        this.mediaconnection.close();
        this.closeLocalStream();
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(stream => {
            stream.addTrack(vstream.getVideoTracks()[0]);
            (<any>document.getElementById("localStream")).srcObject = stream;
            (<any>document.getElementById("localStream"))
              .play()
              .catch(console.error);
            this.localStream = stream;
            setTimeout(() => {
              this.mediaconnection = this.peer.call(peerid, stream, {
                videoReceiveEnabled: true
              });
              this.startStream();
            }, 500);
          });
      })
      .catch((error: any) => {
        finishFunction();
        alert("画面共有に失敗しました");
      });
  }

  static finish() {
    this.videoIds = [];
    this.useVideoId = 0;
    if (this.screenShare) {
      this.screenShare.stop();
    }
    if (this.mediaconnection) {
      this.mediaconnection.close();
      this.mediaconnection = null;
    }
    this.closeLocalStream();
    if (this.peer) {
      this.peer.destroy();
    }
  }

  private static closeLocalStream() {
    if (this.localStream) {
      this.localStream.getTracks().forEach((track: any) => track.stop());
    }
  }

  private static createLocalStream(
    finishFunction: Function,
    errorFunction: Function
  ) {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(stream => {
          (<any>document.getElementById("localStream")).muted = true;
          (<any>document.getElementById("localStream")).srcObject = stream;
          (<any>document.getElementById("localStream"))
            .play()
            .catch(console.error);
          this.localStream = stream;
          resolve();
        })
        .catch(function(e) {
          alert(
            "電話に失敗しました。画面を更新して再度電話をかけてください。\n画面を更新しても失敗する場合はブラウザが対応しているか確認してください。"
          );
          errorFunction();
          finishFunction();
          reject();
        });
    });
  }

  private static startStream() {
    this.mediaconnection.on("stream", (stream: MediaStream) => {
      (<any>document.getElementById("remoteStream")).srcObject = stream;
      (<any>document.getElementById("remoteStream"))
        .play()
        .catch(console.error);
    });
  }
}
