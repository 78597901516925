




































































import Common from "@/common/Common";
import { Component, Prop, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

@Component({
  components: {
    ClipLoader
  }
})
export default class Group extends Vue {
  @Prop()
  public room?: any;

  isUploading: boolean = true;
  userInfo: any = {};
  groups: any = {};
  selected = "";
  text = "";

  mounted() {
    let promises = [];
    promises.push(
      new Promise(resolve => {
        Common.getUserInfo()
          .then((userInfo: any) => {
            this.userInfo = userInfo;
          })
          .finally(() => {
            resolve();
          });
      })
    );
    promises.push(
      new Promise(resolve => {
        Common.getGroups()
          .then((groups: any) => {
            const tids = groups
              .filter((group: any) => group.type == "tenant")
              .map((tenant: any) => tenant.id);
            const tenantDisabled = this.room.gids.some((gid: any) =>
              tids.includes(gid)
            );
            this.groups = groups
              .map((group: any) => {
                let _g = group;
                _g.disabled =
                  group.type == "keio"
                    ? this.room.gids.includes(group.id)
                    : tenantDisabled;
                return _g;
              })
              .sort((a: any, b: any) => a.name.localeCompare(b.name), "ja");
          })
          .finally(() => {
            resolve();
          });
      })
    );

    Promise.all(promises).then(() => {
      this.isUploading = false;
    });
  }

  async addGroup() {
    this.isUploading = true;
    firebase
      .firestore()
      .collection("onayamis")
      .doc(this.$route.params.id)
      .collection("rooms")
      .doc(this.$route.params.chatid)
      .update({
        gids: [...this.room.gids, this.groups[this.selected].id],
        groupText: this.text,
        groupChatCreateTime: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        firebase
          .firestore()
          .collection("onayamis")
          .doc(this.$route.params.id)
          .collection("rooms")
          .doc(this.$route.params.chatid)
          .collection("chats")
          .add({
            text: this.text,
            createTime: firebase.firestore.FieldValue.serverTimestamp(),
            uid: "admin",
            partnerUid: this.groups[this.selected].id,
            type: "add_group",
            read: false,
            push: false
          })
          .then(() => {
            let groups = this.groups;
            if (groups[this.selected].type == "keio") {
              groups[this.selected].disabled = true;
            } else {
              groups = groups.map((group: any) =>
                group.tenant == "keio"
                  ? group
                  : Object.assign(group, { disabled: true })
              );
            }
            this.groups = groups;
            this.selected = "-1";
            this.text = "";
          });
      })
      .finally(() => {
        this.isUploading = false;
      });
  }
}
